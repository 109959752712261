@use '../../scss/abstracts/' as *;

.topnav {
    @include flex(center, space-between);
    margin-bottom: $spacing;

    // @include tablet {
    //     margin-bottom: $mobileSpacing;
    // }
}

.sidebar-toggle {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    @include flex(center, center);
    cursor: pointer;
    display: none;

    // @include tablet {
    //     display: flex;
    // }
    
    i {
        font-size: 3rem;
        color: $mainColor;
    }
}